#burger {

  $padding : 1rem;
  $lineHeight: 2px;
  $size: 1em;
  $animationTime: .2s;
  $color : var(--color-text);
  cursor: pointer;

  width: $size;
  height: $size;
  position: relative;
  padding: $padding;

  span {
	transition: all ease-in-out $animationTime;
	position: absolute;
	width: calc(100% - #{$padding * 2});
	height: $lineHeight;
	display: block;
	left: 1rem;
	background: #0A246A;
  }

  span:first-child {
	top: $padding;
  }

  span:first-child + span {
	top: calc(50% - #{$lineHeight / 2});
  }

  span:first-child + span + span {
	bottom: $padding;
  }

  &.active {
	span:first-child {
	  top: calc(50% - #{$lineHeight / 2});
	  transform: rotate(45deg);
	}

	span:first-child + span + span {
	  bottom: calc(50% - #{$lineHeight / 2});
	  transform: rotate(-45deg);
	}

	span:first-child + span {
	  opacity: 0;
	}
  }

}