// $GRID_TILES - 100%/$GRID_TILES
$GRID_TILES: 12;

// $GRID_CONTAINER_SELECTOR - grid container selector
$GRID_CONTAINER_SELECTOR: ".container";

// $GRID_ITEM_SELECTOR - grid item selector
$GRID_ITEM_SELECTOR: ".col";

// $GRID_WRAPPPER_SELECTOR - selector of the wrapping container
$GRID_WRAPPPER_SELECTOR: ".row";

// $GRID_SPACING - spacing for ur items
$GRID_SPACING: 1rem;

// $GRID_UNIT - choose a which unit you want to use
$GRID_UNIT: "px";

/*
  $GRID_BREAKPOINTS is a list with a variable number of values, each represent a breakpoint
  @example
  --------------------
  $GRID_BREAKPOINTS : (400, 1170);
  generated breakpoints :  0-400; 400-1170; 1170-(∞)

  $GRID_BREAKPOINTS : (544, 768, 992, 1200);
  generated breakpoints :  0-544; 544-768; 768-992; 992-1200 1200-(∞)
*/
$GRID_BREAKPOINTS: (544, 768, 992, 1200);

$GRID_ITEMS: (
                ($GRID_TILES, full),
                (6, half),
                (12, 12, 9, 9, 10, standard),
                (12, 12, 3, 3, 2, sidebar),
);
@mixin GRID_ROW_STYLES {
}

@mixin GRID_TILE_STYLES {

}

@mixin GRID_TILE_INNER_STYLES {
}

@import "../gridy/flexFunctions";
@import "../gridy/flexQueryMixin";
@import "../gridy/_flexGrid";


