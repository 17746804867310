
/* Animation */

@keyframes fadeInUp {
  from {
	transform: translate3d(0,40px,0)
  }

  to {
	transform: translate3d(0,0,0);
	opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
	transform: translate3d(0,40px,0)
  }

  to {
	transform: translate3d(0,0,0);
	opacity: 1
  }
}

.js-inViewPort{
  opacity: 0;
  &.inViewPort{
	animation-duration: .2s;
	animation-fill-mode: both;
	animation-name: fadeInUp;
  }
}