nav.main {

  position: fixed;
  width: 100%;
  box-sizing: border-box;

  top: 0;

  z-index: 999;

  padding: 0 1rem;
  height: 5rem;
  display: flex;
  justify-content: flex-start;
  background-color: var(--color-main);
  border-bottom: 1px solid var(--color-main-border);

  > *:first-child {

  }

  > *:not(:first-child) {
	flex-grow: 1;
	display: flex;
	justify-content: flex-end;

	ul {
	  display: flex;
	  justify-content: flex-end;
	  align-items: center;

	  li {
		padding: 1rem;
		display: flex;

		&.lang-item{
		  display: none;
		  padding: 1rem 0;

		  a{
			text-transform: uppercase;
		  }

		  &.active{
			display: flex;
		  }

		  &:not(:last-child):after{
			content: "/";
			display: inline-block;
			padding: 0 .2rem;
		  }
		  &.current-lang a{
			color: #999 !important;
			pointer-events: none;
		  }
		}
	  }
	}
  }

  #logo {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	a {
	  display: block;
	}

	img {
	  display: block;
	  max-height: 4rem;
	}
  }

  .header-menu {
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	#burger {
	  display: block;
	}

	#burger + div {
	  top: -100vw;
	  right: 100vw;
	  position: fixed;
	  opacity: 0;
	  transition-property: right, opacity;
	  transition-duration: .2s;
	  animation-timing-function: ease;
	}

	#burger.active {
	  z-index: 1001;
	  position: sticky;
	  top: 0;

	  + div {
		top: 0;
		opacity: 1;
		backdrop-filter: blur(20px);
		position: fixed;
		display: block;
		background: rgba(255,255,255,.9);
		bottom: 0;
		right: 0;
		height: 100%;
		width: 100%;
		top: 0;
		z-index: 999;
		ul{
		  margin: 1rem 1rem 1rem 1rem;
		  flex-wrap: wrap;
		  justify-content: flex-start;


		  .lang-item{
			padding: 1rem 0 1rem 1rem;
		  }
		  .lang-item ~ .lang-item{
			padding: 1rem 0;
		  }

		  li{
			width: 100%;
			a{
			  font-size: 120%;
			}
			&.lang-item{
			  width: auto;
			  display: none;
			  &.active{
				display: flex;
			  }
			}
		  }
		}
	  }
	}

	@media (min-width: 800px) {
	  #burger {
		display: none;
	  }
	  #burger + div {
		position: static;
		opacity: 1;
	  }
	}
  }
}



