
:root {
  --color-main: #fff;
  --color-main-border: #eee;
  --color-text: #222;
}


@import "mixin";
@import "gridy/index";
@import "reset";
@import "typo";
@import "header";
@import "burger";
@import "js";
@import "wordpress";
body {
}


.page-outer {
  position: relative;
}

.page-inner {
  max-width: 840px;
  margin: 0 auto;
  padding: 1rem .5rem
}

@media (min-width: 800px) {
  .page-inner {
	padding: 3rem .5rem
  }
}


.home {
  position: relative;
}

footer {
  border-top: 1px solid #ddd;
  padding: 4rem;

  .footer-html {
	text-align: center;
  }
}

#page{
  margin-top: 5rem;
}

nav {
  &.breadcrumb {


	margin-top: -2rem;

	padding: .2rem;
	display: flex;
	display: none;

	justify-content: flex-start;
	align-items: center;

	&, > *, svg {
	  fill: #555;
	  color: #555;
	}

	> * {
	  padding: .2rem .5rem .2rem 0;
	}

	.breadcrumb-home-icon {
	  display: flex;
	  justify-content: center;
	  align-items: center;

	  svg {
		width: .8rem;
		margin-top: -.1rem;
		height: .8rem;
	  }
	}

	.breadcrumb-current-page {
	  line-height: 1em;
	}

	.breadcrumb-delimiter {
	  line-height: 1em;
	}

	font-size: .9rem;

  }

  &.footer {
	ul {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	}
  }
}


#heroImage, #heroSecondaryImage {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#heroSecondaryImage {
  max-height: 400px;
  min-height: 30vh;
}

#heroImage {
  min-height: calc(100vh - 5rem);

  .heroImage-inner {
	max-width: 1800px;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
  }

  .heroImage-headline, .heroImage-content, .heroImage-button {
	margin-bottom: 1rem;
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
  }

  .heroImage-button .button {
	padding: .75rem 1rem 1rem;
	border-radius: .2rem;
	background: #ffc800;
	border-top: 1px solid #ffc655;
	border-bottom: 1px solid #ffbc25;
	display: block;
	box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
	transition: all ease-in-out .2s;

	&:hover {
	  color: inherit;
	  box-shadow: 0 0 0 rgba(0, 0, 0, .0);
	}
  }

  &:before, &:after {
	content: "";
	position: absolute;
	border: 10px solid transparent;
  }

  &:before {
	border-top-color: #503600;
	bottom: calc(3rem - 2px);
	z-index: 998;
  }

  &:after {
	border-top-color: #ffc800;
	bottom: 3rem;
	z-index: 999;
  }
}

.wp-block-column figcaption {
  text-align: center;
}
