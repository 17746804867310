$min_width: 320px;
$max_width: 1200px;
$letter-spacing : 1px;

$text-color: #505050;


body {
  font-family: "PensumPro-Regular", Times, "Times New Roman", serif !important;
  font-weight: normal;
  line-height: 130%;
  @include fluid-type($min_width, $max_width, 18px, 22px);
  color: $text-color;
}

nav.main, nav.footer {
  a {
	color: $text-color !important;
	@include fluid-type($min_width, $max_width, 10px, 14px);
	letter-spacing: $letter-spacing;
	text-decoration: none;
  }
}

a {
  color: #222;
  text-decoration: none;

  &:hover {
	color: #96aa1e;
  }
}

p {
  margin: 0 0 .8em;
}


h1, h2, h3, h4, h5, h6, strong {
  font-family: "PensumPro-Bold", Times, "Times New Roman", serif !important;
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
  color: $text-color;
  line-height: 1.2;
  margin: 0 0 20px;
}

h1.heroImage-headline {
  @include fluid-type($min_width, $max_width, 24px, 40px);
  letter-spacing:$letter-spacing;
  text-align: center;
}
.page-inner{
  h1, h2{
	margin-bottom: 2rem;
  }
  ul li{
	margin-left: 1em;
  }

  ul li:before{
	position: absolute;
	content: "–";
	display: inline-block;
	margin-left: -1em;
	color: #96aa1e!important;
  }
}


h1, h2 {
  @include fluid-type($min_width, $max_width, 28px, 38px);
  letter-spacing: $letter-spacing;
  text-align: center;
  width: 100%;
  display: inline-block;

}

h1, h2{
  &:after {
	content: "";
	width: 34px;
	left: calc(50% - 17px);
	height: 4px;
	text-align: center;
	margin-top: .5rem;
	display: block;
	border-bottom: none;
	background: #96aa1e !important;
	position: absolute;
  }
}

#heroImage h1 {
  color: white;
  text-shadow: 0 2px 5px rgba(0, 0, 0, .3);
  margin: 0 2rem;
  &:after{
	display: none;
  }
}